import GoogleMapReact from 'google-map-react';

import ALink from "../components/common/ALink";
import {useEffect} from "react";
import useTranslation from "../utils/hooks/useTranslation";
import ContactForm from "../forms/contactForm";

export default function ContactUs () {
    const { t } = useTranslation();
    useEffect( () => {
        let offset = document.querySelector( 'body' ).getBoundingClientRect().top + window.pageYOffset - 58;

        setTimeout( () => {
            window.scrollTo( { top: offset, behavior: 'smooth' } );
        }, 50 );

    }, [] )

    const renderMarkers = (map, maps) => {
        return new maps.Marker({
            position: { lat: 41.322721, lng: 19.793697 },
            map,
            title: 'Rruga e Kavajes, Blloku i banesave Park Construction Albania, Tirane'
        });
    }

    return (
        <main className="main">
            <nav aria-label="breadcrumb" className="breadcrumb-nav">
                <div className="container">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <ALink href="/"><i className="icon-home"></i></ALink>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            {t("message.contact_us")}
						</li>
                    </ol>
                </div>
            </nav>

            <div className="container contact-us-container">
                <div className="contact-info">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="ls-n-25 m-b-1">
                                {t("message.contact_info")}
							</h2>
                        </div>

                        <div className="col-sm-6 col-lg-3">
                            <div className="feature-box text-center">
                                <i className="sicon-location-pin"></i>
                                <div className="feature-box-content">
                                    <h3>{t("label.address")}</h3>
                                    <h5>Rruga e Kavajes, Blloku i banesave Park Construction Albania, Tirane</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="feature-box text-center">
                                <i className="fa fa-mobile-alt"></i>
                                <div className="feature-box-content">
                                    <h3>{t("label.phone")}</h3>
                                    <h5>(+355) 69 60 22 333</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="feature-box text-center">
                                <i className="far fa-envelope"></i>
                                <div className="feature-box-content">
                                    <h3>{t("label.email")}</h3>
                                    <h5>sales@ssd.al</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="feature-box text-center">
                                <i className="far fa-calendar-alt"></i>
                                <div className="feature-box-content">
                                    <h3>{t("message.working_days_hours")}</h3>
                                    <h5>{t("message.open_hours")}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mb-5">
                    <div className="col-lg-12">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!4v1734085317527!6m8!1m7!1sCAoSLEFGMVFpcE1zSTJDdFhTejRlSE05YVUwY3hUU2wzNVVyc0Q4VU9XbzlvV1Fh!2m2!1d41.32235675669039!2d19.79272635780811!3f186.89!4f-12.790000000000006!5f0.7820865974627469"
                            style={{
                                border: 0,
                                width: '100%',
                                height: '500px'
                            }} allowFullScreen="" loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <h2 className="mt-6 mb-2">{t("message.send_us_a_message")}</h2>

                        <ContactForm/>
                    </div>

                    <div className="col-lg-6">
                        <div id="map">
                            {/*<GoogleMapReact*/}
                            {/*    layerTypes={[]}*/}
                            {/*    bootstrapURLKeys={{key: process.env.REACT_APP_GOOGLE_MAPS_API}}*/}
                            {/*    defaultCenter={{lat: 41.322721, lng: 19.793697}}*/}
                            {/*    defaultZoom={ 16 }*/}
                            {/*    onGoogleApiLoaded={({map, maps}) => renderMarkers(map, maps)}*/}
                            {/*/>*/}

                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11985.51035572578!2d19.789193087158196!3d41.322401999999975!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x135031b2b6befbcf%3A0xaef577a384ee7278!2sSSD%20Security%20Distribution!5e0!3m2!1sen!2sit!4v1734085581397!5m2!1sen!2sit"
                                 style={{
                                    border: 0,
                                    width: '100%',
                                    height: '600px'
                            }} allowFullScreen="" loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-8"></div>
        </main>
    )
}
